/* styles/Footer.css */
.footer {
    background-color: var(--primary-dark);
    color: var(--text-light);
    padding: var(--spacing-xl) 0;
    margin-top: auto;
}

.footer-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: var(--spacing-xl);
}

.footer-section h3 {
    margin-bottom: var(--spacing-md);
    color: var(--text-light);
}

.contact-details {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
}

.footer-bottom {
    margin-top: var(--spacing-xl);
    text-align: center;
    padding-top: var(--spacing-lg);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
    .footer-content {
        grid-template-columns: 1fr;
        text-align: center;
    }
}