/* styles/Services.css */
.services {
    padding: var(--spacing-xl) 0;
    background-color: var(--background-white);
}

.services h2 {
    text-align: center;
    margin-bottom: var(--spacing-xl);
    font-size: var(--font-size-xl);
    color: var(--text-primary);
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: var(--spacing-xl);
    padding: var(--spacing-md);
}

.service-card {
    padding: var(--spacing-lg);
    background-color: var(--background-white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    text-align: center;
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: translateY(-5px);
}

.service-icon {
    width: 80px;
    height: 80px;
    margin: 0 auto var(--spacing-md);
    transition: transform 0.3s ease;
}

.service-card:hover .service-icon {
    transform: scale(1.1);
}

.service-card h3 {
    color: var(--primary-color);
    margin: var(--spacing-md) 0;
    font-size: var(--font-size-large);
}

.service-card p {
    color: var(--text-secondary);
    line-height: 1.6;
}

@media (max-width: 768px) {
    .services-grid {
        grid-template-columns: 1fr;
    }

    .service-icon {
        width: 60px;
        height: 60px;
    }
}