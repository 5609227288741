/* styles/Features.css */
.features {
    padding: var(--spacing-xl) 0;
    background-color: var(--background-white);
}

.features h2 {
    text-align: center;
    margin-bottom: var(--spacing-xl);
    font-size: var(--font-size-xl);
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: var(--spacing-xl);
    padding: var(--spacing-md);
}

.feature {
    text-align: center;
    padding: var(--spacing-lg);
    transition: transform 0.3s ease;
}

.feature:hover {
    transform: translateY(-5px);
}

.feature h3 {
    color: var(--primary-color);
    margin-bottom: var(--spacing-sm);
}

.feature p {
    color: var(--text-secondary);
}

@media (max-width: 768px) {
    .features-grid {
        grid-template-columns: 1fr;
    }
}