/* styles/Header.css */

/* Certificate Banner */
.certificate-banner {
    background-color: #1E40AF;
    width: 100%;
    overflow: hidden;
}

.cert-image {
    width: 100%;
    height: auto;
    max-height: 180px;
    object-fit: contain;
    display: block;
    margin: 0 auto;
    animation: fadeInScale 0.5s ease-out;
}

/* Header Styles */
.header {
    background: linear-gradient(135deg, #1E3A8A 0%, #2563EB 100%);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Top Bar */
.top-bar {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 12px 0;
}

.contact-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.contact-item,
.address-item {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    transition: all 0.3s ease;
}

.contact-item {
    text-decoration: none;
}

.contact-item:hover {
    color: #BFDBFE;
    transform: translateY(-1px);
}

.icon {
    width: 16px;
    height: 16px;
}

/* Main Header */
.main-header {
    padding: 20px 0;
}

.brand {
    display: flex;
    align-items: center;
    gap: 20px;
    animation: fadeIn 0.6s ease-out;
}

.header-logo {
    width: 80px;
    height: 80px;
    transition: transform 0.3s ease;
}

.header-logo:hover {
    transform: scale(1.05);
}

.brand-info {
    color: #FFFFFF;
}

.brand-info h1 {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    letter-spacing: 0.5px;
    line-height: 1.2;
}

.brand-info p {
    font-size: 16px;
    margin: 5px 0 0;
    opacity: 0.9;
    letter-spacing: 0.5px;
}

/* Animations */
@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale(0.98);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Animation assignments */
.contact-item,
.address-item {
    animation: slideIn 0.4s ease-out forwards;
}

.contact-item:nth-child(2) {
    animation-delay: 0.1s;
}

.address-item {
    animation-delay: 0.2s;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .brand-info h1 {
        font-size: 28px;
    }

    .brand-info p {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    .cert-image {
        max-height: 150px;
    }

    .contact-links {
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .contact-item,
    .address-item {
        justify-content: center;
    }

    .brand {
        flex-direction: column;
        text-align: center;
        gap: 15px;
    }

    .brand-info h1 {
        font-size: 24px;
    }

    .brand-info p {
        font-size: 14px;
    }

    .header-logo {
        width: 60px;
        height: 60px;
    }
}

@media (max-width: 480px) {
    .brand-info h1 {
        font-size: 20px;
    }

    .contact-item,
    .address-item {
        font-size: 13px;
    }

    .container {
        padding: 0 15px;
    }
}

/* Hover Effects */
.contact-item:hover .icon,
.address-item:hover .icon {
    transform: scale(1.1);
}

.contact-links a:hover {
    text-decoration: none;
    color: #BFDBFE;
}

/* Focus States for Accessibility */
.contact-item:focus {
    outline: 2px solid #BFDBFE;
    outline-offset: 2px;
    border-radius: 4px;
}

/* Print Styles */
@media print {
    .header {
        background: none !important;
        color: #000000;
    }

    .cert-image {
        max-height: none;
    }

    .brand-info h1,
    .brand-info p,
    .contact-item,
    .address-item {
        color: #000000;
    }
}