/* styles/index.css */
:root {
  /* Color Scheme */
  --primary-color: #0047AB;
  --primary-dark: #003380;
  --primary-light: #4169E1;
  --secondary-color: #F8F9FA;
  --text-primary: #333333;
  --text-secondary: #666666;
  --text-light: #FFFFFF;
  --background-light: #F5F5F5;
  --background-white: #FFFFFF;
  --border-color: #E0E0E0;

  /* Typography */
  --font-primary: 'Arial', sans-serif;
  --font-secondary: 'Helvetica', sans-serif;
  --font-size-base: 16px;
  --font-size-small: 14px;
  --font-size-large: 18px;
  --font-size-xl: 24px;
  --font-size-xxl: 32px;

  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;

  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;

  /* Shadows */
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  line-height: 1.5;
  color: var(--text-primary);
  background-color: var(--background-light);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}