/* styles/Doctors.css */
.doctors {
    padding: var(--spacing-xl) 0;
    background-color: var(--background-light);
}

.doctors h2 {
    text-align: center;
    margin-bottom: var(--spacing-xl);
    font-size: var(--font-size-xl);
}

.doctors-grid {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); */
    grid-template-columns: 33% repeat(3);
    gap: var(--spacing-xl);
    max-width: 800px;
    margin: 0 auto;
    padding: var(--spacing-md);
}

.doctor-card {
    padding: var(--spacing-lg);
    background-color: var(--background-white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-md);
    text-align: center;
    transition: transform 0.3s ease;
}

.doctor-card:hover {
    transform: translateY(-5px);
}

.doctor-card h3 {
    color: var(--primary-color);
    margin-bottom: var(--spacing-sm);
}

.doctor-card p {
    color: var(--text-secondary);
}

@media (max-width: 768px) {
    .doctors-grid {
        grid-template-columns: 1fr;
    }
}